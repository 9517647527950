<template>
  <div>
    <router-view v-if="settingsLoaded || interventionNeeded || fatalError"></router-view>
    <splashscreen v-else />
    <BModalOrchestrator />
    <ConfirmDialog />
    <DynamicDialog />
    <Toast @click="onClickToast" />
  </div>
</template>

<script>
import Splashscreen from './components/Splashscreen.vue'
import WelcomeMessage from './components/WelcomeMessage.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import AutoUpdateService from './services/AutoUpdateService'
import { initFcm } from '@/services/clients/firebase'
import ConfirmDialog from 'primevue/confirmdialog'
import DynamicDialog from 'primevue/dynamicdialog'
import Toast from 'primevue/toast'
import { useModalController } from 'bootstrap-vue-next'

AutoUpdateService.start()

export default {
  name: 'App',
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  computed: {
    ...mapState(['settingsLoaded', 'organizationId', 'organizationName', 'organizationActive', 'fatalError', 'activeWorkers', 'permissions', 'pendingRoute']),
    ...mapGetters(['hasPendingRoute', 'needsSetup', 'insideOrgNamespace', 'inSignUpWizard']),
    ...mapState('orgService', ['selectedOrg', 'allOrgs']),
    ...mapGetters('orgService', ['userInterventionNeeded']),
    ...mapState({
      jwtServiceInitialized: state => state.jwt.initialized
    }),
    ...mapGetters('jwt', ['email', 'emailVerified', 'displayName', 'providerId']),
    interventionNeeded () {
      return (this.jwtServiceInitialized && !this.emailVerified) || this.userInterventionNeeded
    },
    showWelcomeMessage () {
      return !this.welcomeMessageShown &&
        this.insideOrgNamespace &&
        !this.inSignUpWizard &&
        this.organizationActive &&
        !this.needsSetup &&
        !this.activeWorkers
    }
  },
  data () {
    return {
      welcomeMessageShown: false
    }
  },
  watch: {
    organizationId () {
      this.updateMetadata()
    },
    organizationName () {
      this.updateMetadata()
    },
    displayName () {
      this.updateMetadata()
    },
    email () {
      this.updateMetadata()
    },
    providerId () {
      this.updateMetadata()
    },
    interventionNeeded (value) {
      if (!value) return

      // either user email not verified, org needs acceptance, user fails org auth requirement, no org, or user needs to select one of multiple orgs
      if (!this.emailVerified) {
        // Fatal error dispatched by JwtService
      } else if (!this.selectedOrg) {
        if (this.allOrgs.length < 1) {
          this.$router.push(`/orgs/sign-up`).catch(() => {})
        } else {
          this.$router.push(`/orgs`).catch(() => {})
        }
      } else if (!this.selectedOrg.orgUserAccepted) {
        this.$nextTick(() => this.$router.push(`/orgs/${this.selectedOrg.id}/accept`).catch(() => {}))
      } else if (!this.selectedOrg.orgUserMeetsAuthRuleRequirements) {
        this.$nextTick(() => this.$router.push(`/orgs/${this.selectedOrg.id}/auth-policy-error`).catch(() => {}))
      } // TODO: else????

      this.clearReloadOnErrorFlag()
    },
    settingsLoaded (newVal, oldVal) {
      initFcm(this.$store)

      const currentRoute = this.pendingRoute || this.$router?.currentRoute?.value
      const userRequestedRoute = currentRoute?.matched?.length > 1
      const userRequestedOutsideOrg = userRequestedRoute && !currentRoute?.params?.orgId

      if (userRequestedOutsideOrg) return

      // all the following router navigations wait until next tick, in case there's already a pending route that needs to be handled first
      if (this.needsSetup) {
        this.$nextTick(() => this.$router.push(`/orgs/${this.organizationId}/sign-up`).catch(() => {}))
      } else if (!this.organizationActive) {
        if (this.permissions.includes('manage_subscription')) {
          this.$nextTick(() => this.$router.push(`/orgs/${this.organizationId}/settings/billing/subscription`).catch(() => {}))
        }
      } else {
        // if user didn't already request a specific route inside the org, then redirect to dashboard
        if (!userRequestedRoute) {
          this.$nextTick(() => this.$router.push({ name: 'dashboard', params: { orgId: this.organizationId } }).catch(() => {}))
        }
      }

      this.clearReloadOnErrorFlag()
    },
    fatalError (newVal) {
      if (newVal) this.$router.push('/error')
    },
    showWelcomeMessage (newVal) {
      if (newVal) {
        this.showModal({
          component: WelcomeMessage
        })
        this.welcomeMessageShown = true
      }
    },
    jwtServiceInitialized: {
      handler (newVal) {
        if (!newVal) return

        // If orgId is set on the url, then we'll set up session for that organization.
        // Because a dynamic import of a component can hold up route resolution, we'll check
        // the pending route if the current route is not set.
        const orgId = (this.pendingRoute || this.$route || this.$router?.currentRoute?.value)?.params?.orgId
        this.login(orgId)
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['login']),
    updateMetadata () {
      this.initBugsnag()
    },
    initBugsnag (settings) {
      if (window.Bugsnag) {
        window.Bugsnag.setUser(this.email, this.email, this.displayName)
        window.Bugsnag.addMetadata('user', 'providerId', this.providerId)
        window.Bugsnag.addMetadata('company', {
          id: this.organizationId,
          name: this.organizationName
        })
      }
    },
    clearReloadOnErrorFlag () {
      // See index.html's Bugsnag onError handler for what this local storage item is used for.
      // We'll wait a few seconds in order to help avoid infinite loops.
      if (window.localStorage && window.localStorage.removeItem) {
        setTimeout(() => window.localStorage.removeItem('RELOAD_ON_ERROR'), 5000)
      }
    },
    onClickToast () {
      // TODO: How can we just close the toast that user clicked on?
      this.$toast.closeAll()
    }
  },
  components: {
    ConfirmDialog,
    DynamicDialog,
    Splashscreen,
    Toast
  }
}
</script>

<style>
  /* Import Bootstrap Vue Styles */
  @import 'primeicons/primeicons.css';
</style>
<style lang="scss">
// Import Main styles for this application

// We want to web widget to display directly under the help button,
// not at the bottom of the page.
iframe#webWidget {
  top: 55px;
}

// Make sure modals appear above app page header.
#__BVID__modal-container {
  .modal {
    z-index: 1103 !important;
  }
}
</style>
