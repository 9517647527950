import { computed, getCurrentInstance, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { deepFreeze } from '@/utils/deep-freeze'
import { forceArray } from '@/utils/misc'
import JobService from '@/views/settings/costing/services/JobService'
import JobPhaseService from '@/views/settings/costing/services/JobPhaseService'
import CostCodeService from '@/views/settings/costing/services/CostCodeService'

// TODO: Also temporarily disable requirePerms and work filtering for certain particular orgs until we resolve query branch explosion.
// TODO: Probably need to wait until we upgrade our backend to using native IN queries.
const DISABLE_PERMS_ORG_IDS = [5544977541234688, 5828268817121280, 5835648569180160]
const DISABLE_WORK_LABEL_PERMS_ORG_IDS = [5544977541234688, 5828268817121280]

export const CostingSelectionProps = deepFreeze({
  // Selection values stored in value object.
  value: Object,
  // Attribute names in value object.
  jobLabelAttribute: {
    type: String,
    default: 'jobLabel'
  },
  jobAttribute: {
    type: String,
    default: 'job'
  },
  jobPhaseAttribute: {
    type: String,
    default: 'jobPhase'
  },
  costCodeAttribute: {
    type: String,
    default: 'costCode'
  },
  costingIsPartialAttribute: {
    type: String,
    default: 'costingIsPartial'
  },
  // Parent can provide certain values to filter selections.
  // For bulk actions, we are querying for common values.
  worker: [Number, Array],
  deviceOrgUnit: [Number, Array],
  orgUnit: [Number, Array],
  department: [Number, Array],
  userLabels: Array,
  customFieldValues: Array,
  enablePartialCosting: {
    type: Boolean,
    default: true
  },
  enableJobRequiredValidation: {
    type: Boolean,
    default: false
  },
  disableRequirePerms: {
    type: Boolean,
    default: false
  },
  invalidMessage: {
    type: String,
    default: 'This selection is not valid.'
  }
})
export const CostingSelectionEmits = Object.freeze(['jobChanged', 'validityChanged'])

export function useCostingSelection ({
  props,
  emit,
  usesPartialCosting = true,
  onParentFilterChanged = () => {}
} = {}) {

  const state = reactive({
    // These values each hold entire option object,
    // for filtering and validation.
    // Parent component is responsible to update these
    // values as selection is made, because the fetch
    // functions below don't keep a separate copy of
    // list options that would reconcile new options with
    // existing selected ones.
    job: null,
    jobPhase: null,
    costCode: null
  })
  const store = useStore()
  const enablePartialCosting = computed(() => usesPartialCosting && props.enablePartialCosting)
  const partialCostingChecked = computed(() => enablePartialCosting.value && props.value[props.costingIsPartialAttribute])
  const requireJobAccess = computed(() => store.getters.requireJobAccess)
  const jobLabelEnabled = computed(() => partialCostingChecked.value && !state.job)
  const jobPhaseEnabled = computed(() => store.getters.enhancedCostingEnabled && (state.job ? !!state.job.jobPhaseMode : partialCostingChecked.value))
  const costCodeEnabled = computed(() => store.getters.enhancedCostingEnabled && (state.job ? state.job.costCodeMode : partialCostingChecked.value))
  const customFieldValueParams = computed(() =>
    (props.customFieldValues || [])
      .filter(value => ['bool', 'choice'].includes(value.type))
      .flatMap(value => forceArray(value.value).map(v => `${value.field}:${v}`))
  )
  const orgDisablePerms = computed(() => DISABLE_PERMS_ORG_IDS.includes(store.state.organizationId))
  const orgDisableWorkLabelPerms = computed(() => DISABLE_WORK_LABEL_PERMS_ORG_IDS.includes(store.state.organizationId))

  const v$ = useVuelidate(
    {
      // TODO: Try to do some validation when costing is partial.
      value: {
        [props.jobLabelAttribute]: {
          // It doesn't seem we need any validation for label.
        },
        [props.jobAttribute]: {
          isValid: {
            $message: props.invalidMessage,
            $validator: value => {
              if (enablePartialCosting.value && props.value[props.costingIsPartialAttribute]) return true
              if (value) return true
              if (props.enableJobRequiredValidation && store.state.jobRequired) return false
              return true
            }
          }
        },
        [props.jobPhaseAttribute]: {
          isValid: {
            $message: props.invalidMessage,
            $validator: value => {
              if (!store.getters.enhancedCostingEnabled) return true
              if (enablePartialCosting.value && props.value[props.costingIsPartialAttribute]) return true
              if (!state.job) return !value
              if (!value && state.job.jobPhaseMode === 'require') return false
              if (!state.job.jobPhaseMode) return !value
              if (!state.jobPhase) return true // I think this case is transient during loading
              if (value && state.jobPhase.job && state.jobPhase.job !== props.value[props.jobAttribute]) return false
              return true
            }
          }
        },
        [props.costCodeAttribute]: {
          isValid: {
            $message: props.invalidMessage,
            $validator: value => {
              if (!store.getters.enhancedCostingEnabled) return true
              if (enablePartialCosting.value && props.value[props.costingIsPartialAttribute]) return true
              if (!state.job) return !value
              if (!value && state.job.costCodeMode === 'require') return false
              if (!state.job.costCodeMode) return !value
              if (!state.costCode) return true // I think this case is transient during loading
              if (value && state.costCode.job && state.costCode.job !== props.value[props.jobAttribute]) return false
              return true
            }
          }
        }
      }
    },
    // TODO: Is there a smaller state than entire instance proxy?
    getCurrentInstance().proxy,
    { $scope: false, $stopPropagation: true }
  )

  function fetchJobs ({ searchText, value, limit, cursor }) {
    return JobService
      .list({
        searchText,
        active: 'active',
        id: value,
        limit,
        cursor,
        requirePerms: !props.disableRequirePerms && (!orgDisablePerms.value || requireJobAccess.value),
        worker: props.worker,
        deviceOrgUnit: props.deviceOrgUnit,
        orgUnit: props.orgUnit,
        department: props.department,
        userLabel: props.userLabels,
        customFieldValues: customFieldValueParams.value
      })
  }

  function fetchJobPhases ({ searchText, value, limit, cursor }) {
    const job = props.value[props.jobAttribute]
    return JobPhaseService
      .list({
        searchText,
        active: 'active',
        id: value,
        limit,
        cursor,
        worker: props.worker,
        deviceOrgUnit: props.deviceOrgUnit,
        orgUnit: props.orgUnit,
        department: props.department,
        userLabel: props.userLabels,
        customFieldValues: customFieldValueParams.value,
        workLabel: !orgDisableWorkLabelPerms.value ? state.job?.labels : null,
        // Don't require perms if partial costing without a job, or for certain orgs as explained above.
        ...(enablePartialCosting.value && !job || (orgDisablePerms.value && !requireJobAccess.value) ? {} : {
          job,
          jobPhase: props.value[props.jobPhaseAttribute],
          requirePerms: !props.disableRequirePerms,
        })
      })
  }

  function fetchCostCodes ({ searchText, value, limit, cursor }) {
    const job = props.value[props.jobAttribute]
    return CostCodeService
      .list({
        searchText,
        active: 'active',
        id: value,
        limit,
        cursor,
        worker: props.worker,
        deviceOrgUnit: props.deviceOrgUnit,
        orgUnit: props.orgUnit,
        department: props.department,
        userLabel: props.userLabels,
        customFieldValues: customFieldValueParams.value,
        workLabel: !orgDisableWorkLabelPerms.value ? state.job?.labels : null,
        // Don't require perms if partial costing without a job, or for certain orgs as explained above.
        ...(enablePartialCosting.value && !job || (orgDisablePerms.value && !requireJobAccess.value) ? {} : {
          job,
          jobPhase: props.value[props.jobPhaseAttribute],
          requirePerms: !props.disableRequirePerms,
        })
      })
  }

  watch(
    () => state.job,
    job => {
      // TODO: If job changes, it needs to trigger fetchJobPhases and fetchCostCodes due to permissions effects.
      emit('jobChanged', job)
      if (!store.getters.enhancedCostingEnabled) return
      if (job) {
        // TODO: If jobPhaseMode off, shouldn't we also remove all job phase options?
        // TODO: And if jobPhaseMode off, we might need to clear job phases anyway
        // TODO: because those are the job phases for the previous job.
        // TODO: And when parent id changes we may need to also clear everything,
        // TODO: because org unit / department may have changed.
        // TODO: Similarly with cost codes.
        if (!job.jobPhaseMode) props.value[props.jobPhaseAttribute] = null
        if (!job.costCodeMode) props.value[props.costCodeAttribute] = null
        // Use condition to avoid setting job label null and making form unnecessarily dirty.
        if (jobLabelEnabled.value && props.value[props.jobLabelAttribute]) {
          props.value[props.jobLabelAttribute] = null
        }
      } else {
        props.value[props.jobPhaseAttribute] = null
        props.value[props.costCodeAttribute] = null
      }
    }
  )

  watch(
    partialCostingChecked,
    () => {
      if (!jobPhaseEnabled.value) {
        props.value[props.jobPhaseAttribute] = null
      }
      if (!costCodeEnabled.value) {
        props.value[props.costCodeAttribute] = null
      }
    }
  )

  watch(
    jobLabelEnabled,
    v => {
      if (!v) {
        props.value[props.jobLabelAttribute] = null
      }
    }
  )

  watch(
    () => v$.value.$invalid,
    v => {
      emit('validityChanged', !v)
    },
    { immediate: true }
  )

  watch(
    () => ([props.department, props.deviceOrgUnit]),
    () => { onParentFilterChanged() }
  )

  return {
    state,
    v$,
    jobLabelEnabled,
    jobPhaseEnabled,
    costCodeEnabled,
    fetchJobs,
    fetchJobPhases,
    fetchCostCodes
  }

}
